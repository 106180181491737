<template>
  <div class="text-center">
    <transition name="slide-fade">
      <v-snackbar
        v-if="snackbar"
        v-model="snackbar"
        right
        :timeout="timeout"
        class="snack-small background-infinity"
      >
        <span class="color-text-infinity">
          <b>Atenção: o disco do servidor está {{ diskUsedSpacePerc * 100 | parseNumberToIntegerBR }}% ocupado, com apenas {{ diskFreeSpaceGigabytes | parseNumberToIntegerBR }} GB livres!</b>
        </span>
        <v-btn
          color="black"
          class="mx-4"
          height="35"
          width="100"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </transition>
  </div>
</template>

<script>
import ServerConfigService from '@/services/ServerConfigService';

export default {
  name: 'AlertaDiscoServidor',
  data: () => ({
    snackbar: false,
    timeout: 1000 * 60 * 24, // (24 horas)
    diskFreeSpace: null,
    diskTotalSpace: null,
    diskUsedSpace: null,
    diskUsedSpacePerc: null
  }),
  mounted() {
    this.getStatusDiscoServidor();
  },
  computed: {
    diskFreeSpaceGigabytes() {
      return this.diskFreeSpace / 1024 / 1024 / 1024;
    }
  },
  methods: {
    getStatusDiscoServidor() {
      ServerConfigService.getServerDiskStatus()
        .then(({ data }) => {
          const {
            diskFreeSpace,
            diskTotalSpace,
            diskUsedSpace,
            diskUsedSpacePerc
          } = data;
          this.diskFreeSpace = diskFreeSpace;
          this.diskTotalSpace = diskTotalSpace;
          this.diskUsedSpace = diskUsedSpace;
          this.diskUsedSpacePerc = diskUsedSpacePerc;
          this.snackbar = this.diskFreeSpaceGigabytes <= 20;
        })
        .catch((error) => console.log('Error: ' + error));
    }
  }
};
</script>

<style>
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.snack-small .v-snack__content {
  padding: 0 10px !important;
}
.snack-bg-primary {
  padding: 3px !important;
  border-radius: 50px !important;
}
.background-infinity .v-snack__content {
  animation: changeBackgroundColor 7s infinite;
}
.color-text-infinity {
  animation: changeTextColor 7s infinite;
}
@keyframes changeBackgroundColor {
  0% {
    background-color: #001f3f;
  }
  50% {
    background-color: #ff4136;
  }
  100% {
    background-color: #001f3f;
  }
}
@keyframes changeTextColor {
  0% {
    color: black;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #fff;
  }
}
</style>
